import Vue from "vue";
import VueRouter from "vue-router";
import Inspection from "../views/Inspection";
import api from "../service/api";
import {isWeChatBrowser} from "../utils/browserUtil";

Vue.use(VueRouter);

const routes = [
  {
    path:'/group/order/book',
    component:Inspection,
  },{
    path:'/nearby',
    component:()=>import('../views/nearby'),
  },{
    path:'/store/:storeId',
    component:()=>import('../views/storeDetail'),
  },{
    path:'/inspection/book/:storeId',
    component:()=>import('../views/book'),
  },{
    path:'/orders',
    component:()=>import('../views/order/list'),
  }, {
    path:'/order/:orderNo',
    component:()=>import('../views/order/detail'),
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from,next) =>{

  if(isWeChatBrowser()){
    api.post("/wechat/jsapi", window.location.origin + to.fullPath,{headers: {'Content-Type': 'text/plain'}})
      .then(resp => {
        if(resp.success){
          const sign = resp.data;
          router.app.$wechat.config({
            debug: false,
            appId: sign.appId,
            timestamp: sign.timestamp,
            nonceStr: sign.nonceStr,
            signature: sign.signature,
            jsApiList: [
              'getLocation', 'openLocation', 'onMenuShareTimeline',
              'onMenuShareAppMessage', 'chooseWXPay', 'showMenuItems', 'hideMenuItems',
              'updateAppMessageShareData', 'updateTimelineShareData'
            ]
          });
        }
      });
  }
  next();
});






export default router;
