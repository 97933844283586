export const API_DOMAIN = "http://api.cheyimeng.cn";
export const IMG_DOMAIN = "http://cym.bestweixiao.com";

export const TOKEN_KEY = "CYM_PICC_TOKEN";

export const HOST = `${window.location.protocol}//${window.location.host}`;

/********* 微信配置 ************/
export const AUTHOR_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect';
export const WE_CHAT_APP_ID = "wx5938f3e44446d901";
