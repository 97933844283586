import router from '@/router';

const goBack = () => {
  router.go(-1);
};

const push = (path) => {
  router.push(path);
};

const replaceToIndex = () => {
  router.replace("/index");
};

const pushToIndex = () => {
  router.push("/index");
};
const replaceLogin = () => {
  router.replace("/login");
};
const resetPassword = () => {
  router.replace("/user/reset/password");
};
const replaceInspection = (param) => {
  router.replace({ path: '/group/order/book', query: param});
};

export default {
  replaceLogin,
  goBack,
  push,
  replaceToIndex,
  pushToIndex,
  resetPassword,
  replaceInspection
};
